import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    };
  },
});

router.beforeEach((to, from, next) => {
  const country = router.app.$store.getters['country/country'];
  const programme = router.app.$store.getters['programme/programme'];
  if (to.name !== 'CountryProgramSelect' && ((!country || !country.countryId) || (!programme || !programme.programmeId))) {
    next({ name: 'CountryProgramSelect' });
  } else {
    next();
  }
});

export default router;
