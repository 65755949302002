<template>
  <div
    id="nav"
    :class="routeName">
    <div
      v-if="routeName !== 'recommendations'"
      class="nav-button-wrapper">
      <a
        class='nav-button-wrapper-icon-elipse'
        @click="backAction()">
        <div>
          <font-awesome-icon
            :icon="['fal', 'arrow-left']" />
        </div>
      </a>
       <div class="back-button-text">{{ backButtonText }}</div>
    </div>

    <div
      class="nav-icon-wrapper">
      <img
        v-if="routeName === 'method-information' && method.icon"
        class="nav-icon method-icon"
        :alt="method.name"
        :src="require(`@/assets/images/methods/${method.icon}`)"/>
      <img
        v-else-if="routeName === 'method-information' && !method.icon"
        class="nav-icon method-icon"
        alt="Female Condom Icon"
        src="@/assets/images/methods/female-condom.png"/>
      <img
        v-else-if="routeName === 'methods'"
        class="nav-icon method-icon"
        alt="Female Condom Icon"
        src="@/assets/images/methods/female-condom.png"/>
      <div
        v-else
        class='nav-icon-elipse'
        :class='routeName'>
        <font-awesome-icon
          v-if="routeName === 'recommendations'"
          :icon="['fas', 'star']" />
        <img
          v-else
          class="nav-icon"
          alt="The Pill and Condom Icon"
          src="@/assets/images/2_icon_contraception.png"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { kebabCase, upperCase } from 'lodash';

export default {
  name: 'Nav',

  computed: {
    ...mapGetters('assessment', [
      'answers',
    ]),

    ...mapGetters('method', [
      'methodById',
    ]),

    ...mapGetters('translations', [
      'translate',
    ]),

    routeName() {
      return kebabCase(this.$route.name);
    },

    method() {
      return this.$route.params.methodId ? this.methodById(this.$route.params.methodId) : null;
    },

    backButtonText() {
      return upperCase(this.translate('back', 'back'));
    },
  },

  methods: {
    ...mapActions('assessment', [
      'resetAssessmentAnswers',
      'resetQuestionAnswers',
    ]),

    async backAction() {
      if (this.$route.name === 'Recommendations') {
        await this.resetQuestionAnswers();
        this.$router.push('/questionaire');
        return;
      }

      if (this.$route.name === 'Questionaire') {
        if (this.answers.length) {
          await this.resetQuestionAnswers();
        } else {
          this.$router.push('/');
        }
        return;
      }

      if (this.$route.meta.backTo) {
        this.$router.push(`/${this.$route.meta.backTo}`);
        return;
      }

      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/base/_variables.scss";
  @import "@/assets/scss/base/_mixins.scss";
  #nav {
    width: 100%;
    padding: 1rem 0.875rem 0rem 0.875rem;
    @include media(desktop-only) {
      max-width: $container-max-width;
      margin-left: auto;
      margin-right: auto;
    }

    @include media(tablet-desktop) {
      padding: 1rem 2rem 0rem 2rem;
    }

    &.recommendations {
      padding-top: 3.55rem;
    }

    .nav-button-wrapper {
      display: flex;
      color: $color-grey-light;
      align-items: center;

      .nav-button-wrapper-icon-elipse{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 2.563rem;
        height: 2.563rem;
        background: $color-white-main;
        border-radius: 50%;
        border: 1px solid $color-grey-lighter;
        text-align: center;

        div {
          width: 100%;
          padding-top: 1px;
          font-size: 1.1rem;
        }
      }

      .back-button-text{
        font-size: 0.688rem;
        margin-left: 0.75rem;

        @include media(mobile) {
          display: none;
        }
      }
    }

    .nav-icon-wrapper {
      .nav-icon-elipse{
        position: absolute;
        top: -2.5rem;
      }
      .method-icon {
        position: absolute;
        top: -2.5rem;
        margin-right: 0rem;
        width: 3.5rem;
      }
    }
  }
</style>
