import { api } from '../index';

export default {
  loadCountries(success, failure) {
    return api.get('/get_countries/', {
      params: { type: `${process.env.VUE_APP_ASSESSMENT_TYPE}` },
    })
      .then((response) => success(response))
      .catch((error) => failure(error));
  },
};
