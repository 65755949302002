import { find, findIndex } from 'lodash';

export default {
  namespaced: true,

  state: {
    refData: {},
    refDatas: [],
  },

  mutations: {
    setRefData(state, refData) {
      state.refData = refData;
    },
    addRefData(state, refData) {
      state.refDatas.push({
        questionId: refData.questionId,
        refDataId: refData.refDataId,
      });
    },
    updatedRefData(state, existingRefDataIndex, refData) {
      state.refDatas[existingRefDataIndex].refDataId = refData.refDataId;
    },
    clearState(state) {
      state.refData = {};
    },
  },

  actions: {
  },

  getters: {
    refData: (state) => state.refData,

    refDatas: (state) => state.refDatas,

    refDataByQuestionId: (state) => (questionIdToGet) => {
      return find(state.refDatas, { questionId: questionIdToGet });
    },
  },
};
