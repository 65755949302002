<template>
    <div class="layout-wrapper">
      <Header/>
      <Nav v-if="navHeader"/>

      <router-view class="layout-content-container"/>

      <Footer/>
    </div>
</template>

<script>
import Header from '@/components/headers/Header';
import Nav from '@/components/headers/Nav';
import Footer from '@/components/Footer';

export default {
  name: 'DefaultLayout',

  components: {
    Header,
    Nav,
    Footer,
  },

  computed: {
    navHeader() {
      return this.$route.meta.nav;
    },
  },
};
</script>
