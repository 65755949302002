import { replace, trim } from 'lodash';

export default {
  namespaced: true,

  state: {
    translations: {},
  },

  mutations: {
    setTranslations(state, translations) {
      state.translations = translations;
    },
    clearState(state) {
      state.translations = [];
    },
  },

  actions: {
  },

  getters: {
    translate: (state) => (translateKey, defaultText = null) => {
      const translation = state.translations ? trim(state.translations[translateKey]) : null;
      return translation && translation.length ? replace(translation, '\\', '') : defaultText;
    },
  },
};
