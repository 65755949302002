<template>
  <div id="header">
    <div class="header-logo-wrapper">
      <div @click="goHome">
        <img
          v-if="template.headerLogo"
          class="logo"
          alt="MSI logo"
          :src="template.headerLogo"/>
        <img
          v-else
          class="logo"
          alt="MSI logo"
          src="@/assets/images/header-logo.png"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Header',

  computed: {
    ...mapGetters([
      'isApp',
    ]),

    ...mapGetters('template', [
      'template',
    ]),
  },
  methods: {
    ...mapActions('assessment', [
      'resetAssessmentAnswers',
    ]),

    async goHome() {
      if (this.$route.name === 'Recommendations') {
        await this.resetAssessmentAnswers();
      }
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/base/_variables.scss";
  @import "@/assets/scss/base/_mixins.scss";
  #header {
    width: 100%;
    padding: 1rem;
    background-color: $color-white-main;
    color: $color-blue-darker;

    .header-logo-wrapper {
      display: flex;
      @include media(tablet-desktop) {
        margin-left: auto;
        margin-right: auto;
      }
      @include media(desktop-only) {
        max-width: $desktop-container-max-width;
      }
      @include media(tablet) {
        max-width: $container-max-width;
      }

      .logo {
        cursor: pointer;
        height: 2rem;
      }
    }

  }
</style>
