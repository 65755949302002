import {
  each,
  filter,
  find,
  isObjectLike,
} from 'lodash';
import api from '@/api/endpoints/questionaires';

export default {
  namespaced: true,

  state: {
    queued: [],
  },

  mutations: {
    addToQueue(state, data) {
      if (!find(state.queued, ['id', data.id])) {
        state.queued.push(data);
      }
    },
    removeFromQueue(state, questionaireId) {
      state.queued = filter(state.queued, function (questionaire) {
        return questionaire.id !== questionaireId;
      }) ?? [];
    },
    clearState(state) {
      state.queued = [];
    },
  },

  actions: {
    addQuestionaireToQueue: ({ commit, state }, data) => new Promise((resolve, reject) => {
      commit('addToQueue', data);
      resolve(data);
    }),
    processQueue: ({ dispatch, commit, state }) => new Promise((resolve, reject) => {
      if (navigator.onLine && state.queued.length) {
        each(state.queued, function (queue) {
          return dispatch('sendQuestionaire', queue);
        });
      }
      resolve();
    }),
    sendQuestionaire: ({ commit, rootState }, questionaire) => new Promise((resolve, reject) => {
      const formData = new FormData();

      function appendToFormData(key, value) {
        formData.append(key, value);
      }

      function loopThroughData(loopData, key = null) {
        for (const itemKey in loopData) {
          if (isObjectLike(loopData[itemKey])) {
            if (key) {
              loopThroughData(loopData[itemKey], `${key}[${itemKey}]`);
            } else {
              loopThroughData(loopData[itemKey], itemKey);
            }
          } else {
            const formDataKey = key ? `${key}[${itemKey}]` : itemKey;
            appendToFormData(formDataKey, loopData[itemKey]);
          }
        }
      }

      loopThroughData(questionaire);
      const postData = {
        formData,
      };
      if (rootState.isApp) {
        postData.isApp = true;
        postData.programmeId = questionaire.programmeId;
      }
      api.sendQuestionaire(postData, (response) => {
        const { data } = response;
        if (data.success) {
          commit('removeFromQueue', questionaire.id);
        }
        resolve();
      }, (error) => reject(error));
    }),
  },

  getters: {
    hasQueue: (state) => {
      return state.queued && state.queued.length;
    },
  },
};
