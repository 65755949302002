import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faCheckCircle,
  faCircle,
  faFlag,
  faPhone,
  faQuestionCircle,
  faTimesCircle,
  faStar,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faAngleDown,
  faChevronLeft,
  faChevronRight,
  faHandPointer,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowRight as falArrowRight,
  faArrowLeft as falArrowLeft,
  faArrowToLeft,
  faClipboardList,
  faMinus,
  faPlus,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';

import App from './App';
import router from './router';
import store from './store';

import '@/assets/scss/main.scss';

if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  import('@/services/gtm').then();
}

library.add(
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  falArrowLeft,
  falArrowRight,
  faArrowToLeft,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClipboardList,
  faFlag,
  faHandPointer,
  faMinus,
  faPhone,
  faQuestionCircle,
  faPlus,
  faStar,
  faTimes,
  faTimesCircle,
);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
