import { values } from 'lodash';

export default {
  namespaced: true,

  state: {
    programme: {},
    programmes: [],
  },

  mutations: {
    setProgramme(state, programme) {
      state.programme = programme;
    },
    setProgrammes(state, programmes) {
      state.programmes = programmes;
    },
    clearState(state) {
      state.programme = {};
      state.programmes = [];
    },
  },

  actions: {
  },

  getters: {
    programme: (state) => state.programme,

    programmes: (state) => state.programmes,
  },
};
