import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}`;
export const apiUrl = API_URL;

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    // post: {
    //   'Access-Control-Allow-Origin': '*',
    // },
  },
});
