import { api } from '../index';

export default {
  loadAllData(id, success, failure) {
    return api.get('/get_data/', {
      params: {
        ...(process.env.VUE_APP_ASSESSMENT_TYPE && { type: `${process.env.VUE_APP_ASSESSMENT_TYPE}` }),
        programmeId: id,
      },
    })
      .then((response) => success(response))
      .catch((error) => failure(error));
  },
};
