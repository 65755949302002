import {
  camelCase,
  capitalize,
  clone,
  each,
  filter,
  find,
  findIndex,
  map,
  orderBy,
  startCase,
  sumBy,
  uniqBy,
} from 'lodash';

export default {
  namespaced: true,

  state: {
    method: {},
    methods: [],
    methodScores: [],
    compareMethods: [],
  },

  mutations: {
    setMethod(state, method) {
      state.method = method;
    },
    setMethods(state, methods) {
      state.methods = methods;
    },
    setMethodScores(state, methodScores) {
      state.methodScores = methodScores;
    },
    addMethodToCompare(state, method) {
      state.compareMethods.push(method);
    },
    removeMethodToCompare(state, method) {
      state.compareMethods = filter(state.compareMethods, function (compareMethod) {
        return compareMethod.methodId !== method.methodId;
      }) ?? [];
    },
    clearCompareMethods(state) {
      state.compareMethods = [];
    },
    clearState(state) {
      state.method = {};
      state.methods = [];
      state.methodScores = [];
      state.compareMethods = [];
    },
  },

  actions: {
    addMethodToCompare: ({ commit, state }, newMethod) => new Promise((resolve) => {
      const existingMethodIndex = findIndex(state.compareMethods, { methodId: newMethod.methodId });
      if (existingMethodIndex >= 0) {
        commit('removeMethodToCompare', newMethod);
      } else {
        commit('addMethodToCompare', newMethod);
      }
      resolve();
    }),

    resetCompareMethods: ({ commit }) => new Promise((resolve) => {
      commit('clearCompareMethods');
      resolve();
    }),
  },

  getters: {
    method: (state) => state.method,

    methods: (state) => state.methods,

    methodScores: (state) => state.methodScores,

    methodById: (state) => (id) => {
      return find(state.methods, ['methodId', id]) ?? null;
    },

    methodBySlug: (state) => (slug) => {
      return find(state.methods, ['name', capitalize(startCase(camelCase(slug)))]) ?? null;
    },

    relevantMethodScores: (state, getters, rootState) => {
      const discardedMethodsIds = [];

      // If a question was answered AND it is flagged as discarded, we store it to be removed later
      state.methodScores.forEach((methodScore) => {
        const questionAnswered = find(rootState.assessment.answers, (answer) => {
          if (answer.questionId == methodScore.questionId) {
            return find(answer.answers, (a) => { return a == methodScore.refDataId; }) ?? false;
          }
          return false;
        }) ?? false;

        if ((questionAnswered && methodScore.discard)) {
          discardedMethodsIds.push(methodScore.methodId);
        }
      });

      return filter(state.methodScores, (methodScore) => {
        // Remove discarded methodScores
        if (discardedMethodsIds.includes(methodScore.methodId)) {
          return false;
        }

        return find(rootState.assessment.answers, function (answer) {
          const questionAnswered = answer.questionId == methodScore.questionId;
          if (questionAnswered) {
            return find(answer.answers, function (a) { return a == methodScore.refDataId; }) ?? false;
          }
          return false;
        }) ?? false;
      });
    },

    uniqueRelevantMethodScores: (state, getters) => {
      const uniqueMethodScores = clone(uniqBy(getters.relevantMethodScores, 'methodId'));
      return orderBy(each(uniqueMethodScores, function (methodScore) {
        const matchingMethodScores = filter(getters.relevantMethodScores, ['methodId', methodScore.methodId]);
        methodScore.questions = map(matchingMethodScores, 'questionId');
        methodScore.scores = map(matchingMethodScores, 'score');
        methodScore.totalScore = sumBy(matchingMethodScores, function (method) {
          return method.score ?? 0;
        });
      }), 'totalScore', 'desc');
    },

    relevantMethodScoresByMethodId: (state, getters) => (methodIdToGet) => {
      return filter(getters.relevantMethodScores, ['methodId', methodIdToGet]) ?? [];
    },

    relevantMethodScoresByMethodIdAndType: (state, getters) => (methodIdToGet, methodType) => {
      return filter(getters.relevantMethodScoresByMethodId(methodIdToGet), function (method) {
        if (methodType == 'ideal') {
          return method.relevancy >= 4;
        }
        if (methodType == 'imperfect') {
          return method.relevancy < 4 && method.relevancy >= 2;
        }
        if (methodType == 'notMatch') {
          return method.relevancy < 2 && method.relevancy >= 0;
        }
        return false;
      }) ?? [];
    },

    methodsToCompare: (state) => state.compareMethods,

    methodsToCompareByMethodId: (state) => (methodIdToGet) => {
      return find(state.compareMethods, ['methodId', methodIdToGet]) ?? null;
    },
  },
};
