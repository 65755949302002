<template>
  <div class="home contained">
    <div
      class="nav-icon-wrapper">
      <div
        class='nav-icon-elipse'
        :class='routeName'>
        <img
          class="nav-icon"
          alt="The Pill and Condom Icon"
          src="@/assets/images/2_icon_contraception.png"/>
      </div>
    </div>

    <b-jumbotron
      class="page-header"
      :header="programme.homepageIntroText"
      :lead="(isApp) ? programme.homepageIntroTextApp : programme.homepageIntroTextWeb" />

    <b-card>
      <div class="card-head">
        <div class="card-icon-wrapper">
          <div class='card-icon-elipse'>
            <img
              alt="Counselling Session Icon"
              src="@/assets/images/document-big.svg"/>
          </div>
        </div>
        <b-card-title>
          {{ programme.homepageSurveyButtonTitle }}
        </b-card-title>
      </div>

      <b-card-text>
        {{ programme.homepageSurveyButtonText }}
      </b-card-text>

      <b-button
        @click="newAssessment()"
        block
        variant="secondary"
        class="leading">
        <font-awesome-icon :icon="['fas', 'arrow-right']" />
        <span class="button-text">{{ translate(counsellingCard.buttonText, counsellingCard.buttonText) }}</span>
      </b-button>
    </b-card>

    <b-card
      v-if="isApp"
      class="mt-3 mb-3">
      <div class="card-head">
        <div class="card-icon-wrapper">
          <div class='card-icon-elipse explore'>
            <img
              alt="Explore Methods Icon"
              src="@/assets/images/method-icon-big.png"/>
          </div>
        </div>

        <b-card-title>
          {{ programme.homepageMethodButtonTitle }}
        </b-card-title>
      </div>

      <b-card-text>
        {{ programme.homepageMethodButtonText }}
      </b-card-text>

      <b-button
        :to="methodsCard.bottonLink"
        block
        variant="secondary"
        class="leading">
        <font-awesome-icon :icon="['fas', 'arrow-right']" />
        <span class="button-text"></span>
        {{ translate(methodsCard.buttonText, methodsCard.buttonText) }}
      </b-button>
    </b-card>

    <b-card
      v-else
      class="mt-3 got-a-question">
      <div class="card-head">
        <div class="card-icon-wrapper question-icon">
          <img
            alt="Question Icon"
            src="@/assets/images/icon-contact.svg"/>
        </div>

        <b-card-title>
          {{ programme.homepageHaveQuestionTitle }}
        </b-card-title>
      </div>

      <b-card-text>
        {{ programme.homepageHaveQuestionText }}
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { kebabCase } from 'lodash';

export default {
  name: 'Home',

  data: () => ({
    jumbotron: {
      header: 'homepageIntroText',
      headerDefault: 'Welcome to the contraception counsellor',
      lead: 'Please select how you would like to continue',
      leadDefault: 'Please select how you would like to continue',
    },
    counsellingCard: {
      title: 'homeStartAssessmentTitle',
      titleDefault: 'Start counselling session now',
      icon: [
        'fal',
        'clipboard-list',
      ],
      content: 'homeStartAssessmentText',
      contentDefault: 'Take the client through a questionnaire to find their best contraceptive fit',
      bottonLink: 'questionaire',
      buttonText: 'continue',
    },
    methodsCard: {
      title: 'homeMethodsTitle',
      titleDefault: 'Explore available methods',
      icon: [
        'fal',
        'clipboard-list',
      ],
      content: 'homeMethodsText',
      contentDefault: 'Compare or view information on specific contraceptive methods',
      bottonLink: 'methods',
      buttonText: 'continue',
    },
    questionCard: {
      title: 'homeQuestionsTitle',
      titleDefault: 'Got a question?',
      icon: [
        'fal',
        'clipboard-list',
      ],
      content: 'If you\'d like to talk someone about your options, please get in touch on 0345 300 8090',
      contentDefault: 'If you\'d like to talk someone about your options, please get in touch on 0345 300 8090',
    },
  }),

  mounted() {
    this.resetAssessment();
  },

  computed: {
    ...mapGetters([
      'isApp',
    ]),

    ...mapGetters('programme', [
      'programme',
    ]),

    ...mapGetters('questionaire', [
      'hasQueue',
    ]),

    ...mapGetters('template', [
      'template',
    ]),

    ...mapGetters('translations', [
      'translate',
    ]),

    routeName() {
      return kebabCase(this.$route.name);
    },
  },

  methods: {
    ...mapActions([
      'saveQuestionaire',
    ]),

    ...mapActions('assessment', [
      'resetAssessmentAnswers',
      'startAssessment',
    ]),

    ...mapActions('questionaire', [
      'processQueue',
    ]),

    async resetAssessment() {
      await this.saveQuestionaire();
      await this.resetAssessmentAnswers();
    },

    async newAssessment() {
      await this.startAssessment();
      this.$router.push(`/${this.counsellingCard.bottonLink}`);
    },
  },
};
</script>

<style lang="scss" scoped>
  .logo {
    height: 3rem;
    margin-bottom: 1.125rem;
  }
</style>
