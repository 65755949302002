import Home from '@/views/pages/Home';
import store from '@/store';

const routes = [
  {
    path: '/country-program-select',
    name: 'CountryProgramSelect',
    //  route level code-splitting
    // this generates a separate chunk (countryProgramSelect.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "countryProgramSelect" */ '@/views/pages/CountryProgramSelect'),
    meta: {
      nav: false,
    },
    async beforeEnter(to, from, next) {
      if (!store.getters.isApp) {
        try {
          await store.dispatch('getAllData');
          next('/');
        } catch (e) {
          console.log('Could not fetch programme, falling back to selection...');
        }
      }

      next();
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      nav: false,
    },
    async beforeEnter(to, from, next) {
      if (!store.getters.isApp) {
        try {
          await store.dispatch('getAllData');
        } catch (e) {
          console.log('Could not fetch programme, falling back to selection...');
        }
      }

      next();
    },
  },
  {
    path: '/questionaire',
    name: 'Questionaire',
    //  route level code-splitting
    // this generates a separate chunk (questionaire.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "questionaire" */ '@/views/pages/Questionaire'),
    meta: {
      nav: true,
    },
  },
  {
    path: '/recommendations',
    name: 'Recommendations',
    //  route level code-splitting
    // this generates a separate chunk (recommendations.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "recommendations" */ '@/views/pages/Recommendations'),
    meta: {
      nav: true,
      navIcon: {
        type: 'fa',
        eclipse: true,
        class: '',
        alt: '',
        src: ['fas', 'star'],
      },
    },
  },
  {
    path: '/accordion',
    name: 'Accordion',
    //  route level code-splitting
    // this generates a separate chunk (accordion.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "accordion" */ '@/views/pages/Accordion'),
    meta: {
      nav: true,
    },
  },
  {
    path: '/methods',
    name: 'Methods',
    //  route level code-splitting
    // this generates a separate chunk (methods.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "methods" */ '@/views/pages/Methods'),
    meta: {
      nav: true,
    },
  },
  {
    path: '/method-information/:methodId/:methodName',
    name: 'MethodInformation',
    //  route level code-splitting
    // this generates a separate chunk (methodInformation.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "methodInformation" */ '@/views/pages/MethodInformation'),
    meta: {
      nav: true,
    },
  },
  {
    path: '/compare-select',
    name: 'CompareSelect',
    //  route level code-splitting
    // this generates a separate chunk (compareSelect.[hash].js) for this route
    //  which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "compareSelect" */ '@/views/pages/CompareSelect'),
    meta: {
      nav: true,
      backTo: 'methods',
    },
  },
  {
    path: '/methods-comparison-table',
    name: 'MethodsComparisonTable',
    component: () => import(/* webpackChunkName: "methodsComparisonTable" */ '@/views/pages/MethodsComparisonTable'),
    meta: {
      nav: true,
    },
  },
];

export default routes;
