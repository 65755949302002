import { api } from '../index';

export default {
  sendQuestionaire(postData, success, failure) {
    let url = '/save_results';
    if (postData.isApp && postData.programmeId) {
      url = `${url}?programmeId=${postData.programmeId}`;
    }
    return api.post(url,
      postData.formData)
      .then((response) => success(response))
      .catch((error) => failure(error));
  },
};
