import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {
  each,
  filter,
  first,
  groupBy,
  map,
  mapKeys,
  mapValues,
} from 'lodash';
import api from '@/api/endpoints/allData';

Vue.use(Vuex);

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/);

const modules = requireContext.keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((allModules, [name, module]) => {
    allModules[name] = module.default;
    return allModules;
  }, {});

const dataState = createPersistedState({
  paths: [
    'answers',
    'answerModalContents',
    'answerProsConsLabels',
    'assessment',
    'compareMethods',
    'country',
    'countries',
    'method',
    'methods',
    'methodScores',
    'programme',
    'programmes',
    'questions',
    'queued',
    'rules',
    'template',
    'translations',
  ],
});

export default new Vuex.Store({
  state: {
    isApp: process.env.VUE_APP_SETUP === 'app',
    reset: false,
  },
  plugins: [dataState],
  mutations: {
    toggleReset(state) {
      state.reset = !state.reset;
    },
  },
  actions: {
    getAllData: ({ commit }, programmeId) => new Promise((resolve, reject) => {
      api.loadAllData(programmeId, (response) => {
        const { data } = response;
        commit('assessment/setAnswerProsConsLabels', data.answerProsConsLabels);
        commit('assessment/setAnswerModalContents', data.answerModalContents);
        commit('assessment/setAssessment', data.assessment);
        commit('assessment/setQuestions', data.questions);
        commit('assessment/setRules', data.rules);
        commit('country/setCountry', data.country);
        commit('method/setMethods', data.methods);
        commit('method/setMethodScores', data.methodScores);
        commit('programme/setProgramme', data.programme);
        commit('refData/setRefData', data.refData);
        commit('template/setTemplate', data.template);
        commit('translations/setTranslations', data.translations);
        resolve(data);
      }, (error) => reject(error));
    }),
    resetEntireProgramme: ({ commit }) => new Promise((resolve, reject) => {
      commit('assessment/clearState');
      commit('country/clearCountry');
      commit('method/clearState');
      commit('programme/clearState');
      commit('refData/clearState');
      commit('template/clearState');
      commit('translations/clearState');
      commit('toggleReset');
      resolve();
    }),
    async saveQuestionaire({ dispatch, commit, getters }) {
      let answers = getters['assessment/answers'];
      if (answers.length) {
        answers = map(answers, function (answer) {
          const filterdOutContinueAnswers = filter(answer.answers, function (userAnswer) {
            return userAnswer !== 'continue';
          });

          return {
            parentQuestionId: answer.parentQuestionId ?? answer.questionId,
            questionId: answer.questionId,
            answers: filterdOutContinueAnswers,
            type: answer.type,
          };
        });

        answers = filter(answers, function (answer) { return answer.answers.length; });

        answers = groupBy(answers, 'parentQuestionId');

        answers = map(answers, function (answer, answerKey) {
          if (answer.length == 1) {
            const answerToUse = first(answer);
            return {
              questionId: answerKey,
              userAnswer: answerToUse.type == 'single' ? first(answerToUse.answers) : answerToUse.answers,
            };
          }

          const mappedUserAnswers = [];
          each(answer, function (tableAnswer) {
            mappedUserAnswers[tableAnswer.questionId] = first(tableAnswer.answers);
          });

          return {
            questionId: answerKey,
            userAnswer: mappedUserAnswers,
          };
        });

        let results = getters['method/uniqueRelevantMethodScores'];

        results = map(results, function (result) {
          const idealProsCons = filter(getters['method/relevantMethodScoresByMethodIdAndType'](result.methodId, 'ideal'), function (method) {
            return getters['assessment/answerProsConsLabelsByQuestionAndRefDataId'](method.questionId, method.refDataId, 'ideal');
          });
          const idealProsConsValues = mapValues(mapKeys(map(idealProsCons, function (idealProCon) {
            let refData = [{
              refDataKey: idealProCon.refDataId,
              refDataValue: getters['assessment/answerProsConsLabelsByQuestionAndRefDataId'](idealProCon.questionId, idealProCon.refDataId, 'ideal'),
            }];
            refData = mapKeys(refData, function (value) {
              return value.refDataKey;
            });
            refData = mapValues(refData, 'refDataValue');

            return {
              question: `q${idealProCon.questionId}`,
              refData,
            };
          }), function (value, key) {
            return value.question;
          }), 'refData');

          const imperfectProsCons = filter(getters['method/relevantMethodScoresByMethodIdAndType'](result.methodId, 'imperfect'), function (method) {
            return getters['assessment/answerProsConsLabelsByQuestionAndRefDataId'](method.questionId, method.refDataId, 'imperfect');
          });
          const imperfectProsConsValue = mapValues(mapKeys(map(imperfectProsCons, function (imperfectProCon) {
            let refData = [{
              refDataKey: imperfectProCon.refDataId,
              refDataValue: getters['assessment/answerProsConsLabelsByQuestionAndRefDataId'](imperfectProCon.questionId, imperfectProCon.refDataId, 'imperfect'),
            }];
            refData = mapKeys(refData, function (value) {
              return value.refDataKey;
            });
            refData = mapValues(refData, 'refDataValue');

            return {
              question: `q${imperfectProCon.questionId}`,
              refData,
            };
          }), function (value, key) {
            return value.question;
          }), 'refData');

          const notAMatchProsCons = filter(getters['method/relevantMethodScoresByMethodIdAndType'](result.methodId, 'notAMatch'), function (method) {
            return getters['assessment/answerProsConsLabelsByQuestionAndRefDataId'](method.questionId, method.refDataId, 'notAMatch');
          });

          const notAMatchProsConsValue = mapValues(mapKeys(map(notAMatchProsCons, function (notAMatchProCon) {
            let refData = [{
              refDataKey: notAMatchProCon.refDataId,
              refDataValue: getters['assessment/answerProsConsLabelsByQuestionAndRefDataId'](notAMatchProCon.questionId, notAMatchProCon.refDataId, 'notAMatch'),
            }];
            refData = mapKeys(refData, function (value) {
              return value.refDataKey;
            });
            refData = mapValues(refData, 'refDataValue');

            return {
              question: `q${notAMatchProCon.questionId}`,
              refData,
            };
          }), function (value, key) {
            return value.question;
          }), 'refData');

          return {
            methodId: result.methodId,
            score: result.totalScore,
            prosCons: {
              ideal: idealProsConsValues,
              imperfect: imperfectProsConsValue,
              notAMatch: notAMatchProsConsValue,
            },
          };
        });

        const questionaireId = getters['assessment/questionaireId'];
        const data = {
          id: questionaireId,
          programmeId: getters['programme/programme'].programmeId,
          type: 'client',
          answers,
          methods: results,
          // the server expects timestamp in seconds, not milliseconds
          responseDate: questionaireId,
        };

        await dispatch('questionaire/addQuestionaireToQueue', data);
      }
      dispatch('questionaire/processQueue');
      return 'done';
    },
  },

  getters: {
    isApp: (state) => {
      return state.isApp;
    },
    isReset: (state) => {
      return state.reset;
    },
  },

  modules,
});
