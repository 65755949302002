<template>
  <div id="footer">
    <!-- The modal -->
    <b-modal
      v-if="isApp"
      id="modal-reset-programme"
      :hideFooter="true">
      <template #modal-title class="danger">
        {{ translate(resetText.title, resetText.titleDefault) }}
      </template>
      <div>
        {{ translate(resetText.confirmation, resetText.confirmationDefault) }}
        {{ translate(resetText.clearWarning, resetText.clearWarningDefault) }}
        <b-button
          block
          variant="danger"
          class="reset-button"
          :disabled="!isOnLine || loading"
          @click="continueToReset()">
          <span class="button-text">{{ translate(resetText.buttonText, resetText.buttonTextDefault) }}</span>
        </b-button>
        <div v-if="!isOnLine" class="danger">
          {{ translate(resetText.notOnline, resetText.notOnlineDefault) }}
        </div>
      </div>
    </b-modal>
    <div
      v-if="isApp && routeName !== 'country-program-select'"
      class="nav-button-wrapper">
      <b-button
        v-b-modal="'modal-reset-programme'"
        class='nav-button-wrapper-icon-elipse'>
        <div>
          <font-awesome-icon
            :icon="['fal', 'arrow-to-left']" />
        </div>
      </b-button>
    </div>
    <div class="copyright-text-wrapper">
      &#169; {{ copyright() }}  {{ new Date().getFullYear() }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { kebabCase } from 'lodash';

export default {
  name: 'Footer',

  data: () => ({
    loading: false,
    isOnLine: navigator.onLine,
    copyrightText: 'copyrightText',
    copyrightDefaultText: 'MSI Reproductive Choices',
    resetText: {
      buttonTextDefault: 'Reset',
      buttonText: 'reset',
      title: 'resetProgramme',
      titleDefault: 'Reset Programmes',
      confirmation: 'resetProgrammeConfirmation',
      confirmationDefault: 'Are you sure that you want to reset the programme?',
      clearWarning: 'resetProgrammeClearData',
      clearWarningDefault: 'This will clear all data and you will need an active internet connection in order to restart.',
      notOnline: 'notOnline',
      notOnlineDefault: 'You do not have an active internet connection which is required to reset the programme.',
    },
  }),

  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },

  computed: {
    ...mapGetters([
      'isApp',
    ]),
    ...mapGetters('translations', [
      'translate',
    ]),

    routeName() {
      return kebabCase(this.$route.name);
    },
  },

  methods: {
    ...mapMutations([
      'toggleReset',
    ]),

    ...mapActions([
      'saveQuestionaire',
    ]),
    updateOnlineStatus(type) {
      this.isOnLine = type === 'online';
    },

    async continueToReset() {
      this.loading = true;
      await this.saveQuestionaire();
      this.toggleReset();
      this.$bvModal.hide('modal-reset-programme');
      this.loading = false;
      this.$router.push('/country-program-select');
    },
    copyright() {
      return this.translate(this.copyrightText, this.copyrightDefaultText);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/base/_variables.scss";

  #footer {
    font-size: 0.813rem;
    width: 100%;
    padding: 1rem;
    background-color: $color-white-main;
    color: $color-blue-darker;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .nav-button-wrapper {
      align-self: flex-start;
      position: absolute;
      .btn.btn-secondary {
        background-color: unset;
      }
    }
  }
</style>
