import { values } from 'lodash';

export default {
  namespaced: true,

  state: {
    template: {},
  },

  mutations: {
    setTemplate(state, template) {
      state.template = template;
    },
    clearState(state) {
      state.template = {};
    },
  },

  actions: {
  },

  getters: {
    template: (state) => state.template,
  },
};
