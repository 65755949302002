import { find, values } from 'lodash';
import api from '@/api/endpoints/countries';

export default {
  namespaced: true,

  state: {
    country: null,
    countries: [],
  },

  mutations: {
    setCountry(state, country) {
      state.country = country;
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    clearCountry(state) {
      state.country = null;
    },
    clearState(state) {
      state.country = null;
      state.countries = [];
    },
  },

  actions: {
    loadCountries: ({ commit }) => new Promise((resolve, reject) => {
      api.loadCountries((response) => {
        const countries = values(response.data.countries);
        commit('setCountries', countries);
        resolve(countries);
      }, (error) => reject(error));
    }),
  },

  getters: {
    country: (state) => state.country,

    countries: (state) => state.countries,

    countryById: (state) => (id) => {
      return find(state.countries, ['countryId', id]) ?? null;
    },
  },
};
