import {
  difference,
  each,
  find,
  filter,
  first,
  findIndex,
  last,
  nth,
  orderBy,
} from 'lodash';

export default {
  namespaced: true,

  state: {
    assessment: {},

    questions: [],

    rules: [],

    answers: [],
    questionaireId: null,
    answerModalContents: [],
    answerProsConsLabels: [],
  },

  mutations: {
    setAssessment(state, assessment) {
      state.assessment = assessment;
    },

    setQuestionaireId(state, id) {
      state.questionaireId = id;
    },

    setQuestion(state, question) {
      state.question = question;
    },
    setQuestions(state, questions) {
      state.questions = questions;
      if (!state.question) {
        state.question = first(questions);
      }
    },

    setAnswers(state, answers) {
      state.answers = answers;
    },
    addAnswer(state, answer) {
      state.answers.push({
        questionId: answer.questionId,
        parentQuestionId: answer.parentQuestionId ?? null,
        type: answer.questionType,
        answers: answer.answers,
      });
    },
    addAdditionalAnswer(state, data) {
      state.answers[data.index].answers.push(data.answer);
    },
    updatedAnswer(state, data) {
      state.answers[data.index].answers = data.answer.answers;
    },
    setAnswerModalContents(state, answerModalContents) {
      state.answerModalContents = answerModalContents;
    },
    setAnswerProsConsLabels(state, answerProsConsLabels) {
      state.answerProsConsLabels = answerProsConsLabels;
    },

    setRules(state, rules) {
      state.rules = rules;
    },

    clearAnswers(state) {
      state.answers = [];
      state.questionaireId = null;
    },
    clearQuestionAnswer(state, data) {
      state.answers = filter(state.answers, function (answer) {
        return answer.questionId !== data.questionId;
      });
    },
    clearQuestionAnswerContinue(state) {
      last(state.answers).answers = filter(last(state.answers).answers, function (answer) {
        return answer !== 'continue';
      });
    },

    clearState(state) {
      state.assessment = {};

      state.questions = [];

      state.rules = [];

      state.answers = [];
      state.questionaireId = null;
      state.answerModalContents = [];
      state.answerProsConsLabels = [];
    },
  },

  actions: {
    startAssessment: ({ commit }) => new Promise((resolve) => {
      const id = Math.floor(new Date() / 1000);
      commit('setQuestionaireId', id);
      resolve();
    }),

    addAnswer: ({ commit, state, getters }, newAnswer) => new Promise((resolve) => {
      const existingAnsweredQuestionIndex = findIndex(state.answers, { questionId: newAnswer.questionId });
      const answers = [newAnswer.refDataId];
      newAnswer.answers = answers;
      if (existingAnsweredQuestionIndex >= 0) {
        const lastActiveOption = nth(getters.activeQuestion.options, -1);
        const existingAnswerIndex = findIndex(state.answers[existingAnsweredQuestionIndex].answers, function (answer) {
          return answer === newAnswer.refDataId;
        });
        if (existingAnswerIndex >= 0) {
          newAnswer.answers = filter(state.answers[existingAnsweredQuestionIndex].answers, function (answer) {
            return answer !== newAnswer.refDataId;
          });
          if (newAnswer.answers.length) {
            commit('updatedAnswer', { index: existingAnsweredQuestionIndex, answer: newAnswer });
          } else {
            commit('clearQuestionAnswer', newAnswer);
          }
        } else if (newAnswer.questionType !== 'multi' && newAnswer.refDataId !== 'continue' && ((findIndex(getters.activeQuestion.options, { refDataId: newAnswer.refDataId }) == getters.activeQuestion.options.length - 1) || (newAnswer.refDataId !== lastActiveOption.refDataId && findIndex(state.answers[existingAnsweredQuestionIndex].answers, function (answer) {
          return answer === lastActiveOption.refDataId;
        }) >= 0))) {
          commit('updatedAnswer', { index: existingAnsweredQuestionIndex, answer: newAnswer });
        } else if (newAnswer.questionType == 'table') {
          commit('updatedAnswer', { index: existingAnsweredQuestionIndex, answer: newAnswer });
        } else {
          commit('addAdditionalAnswer', { index: existingAnsweredQuestionIndex, answer: newAnswer.refDataId });
        }
      } else {
        commit('addAnswer', newAnswer);
      }
      resolve();
    }),

    resetAssessmentAnswers: ({ commit }) => new Promise((resolve) => {
      commit('clearAnswers');
      resolve();
    }),

    resetQuestionAnswers: ({ commit, state }) => new Promise((resolve) => {
      const previousAnswer = last(state.answers);
      if (previousAnswer.type === 'table') {
        const tableAnswers = filter(state.answers, ['parentQuestionId', previousAnswer.questionId]);
        each(tableAnswers, function (answer) {
          commit('clearQuestionAnswer', answer);
        });
      }
      commit('clearQuestionAnswer', previousAnswer);
      resolve();
    }),
  },

  getters: {
    assessment: (state) => state.assessment,

    questionaireId: (state) => state.questionaireId,

    questions: (state) => state.questions,

    visibileQuestions: (state, getters) => {
      return filter(state.questions, function (question) {
        const defaultVisible = question.defaultVisibility === 'show';
        const appliedRules = getters.appliedRulesTargetingQuestionById(question.questionId);
        if (appliedRules.length) {
          const lastAppliedRule = last(appliedRules);
          return lastAppliedRule && lastAppliedRule.action === 'show';
        }
        return defaultVisible;
      });
    },

    unansweredQuestions: (state, getters) => {
      const as = state.answers;
      if (getters.skipAllRules) {
        const lastAnswer = last(state.answers);
        if (lastAnswer && findIndex(last(state.answers).answers, function (a) { return a === 'continue'; }) >= 0) {
          return [];
        }
      }
      const unanswered = filter(getters.visibileQuestions, function (question) {
        const found = !find(state.answers, function (answer) {
          const questionAnswered = answer.questionId === question.questionId;
          if (questionAnswered && findIndex(answer.answers, function (a) { return a === 'continue'; }) >= 0) {
            return true;
          }
          return false;
        });
        return found;
      });
      return unanswered ?? [];
    },

    activeQuestion: (state, getters) => {
      return first(getters.unansweredQuestions) ?? null;
    },

    optionsByQuestionId: (state) => (questionIdToGet) => {
      return orderBy(find(state.questions, { questionId: questionIdToGet }).options, 'position');
    },

    unansweredActiveSubQuestions: (state, getters) => {
      if (getters.activeQuestion && getters.activeQuestion.subQuestions) {
        return filter(getters.activeQuestion.subQuestions, function (question) {
          const found = !find(state.answers, function (answer) {
            return answer.questionId === question.questionId;
          });
          return found;
        }) ?? [];
      }
      return [];
    },

    rules: (state) => state.rules,

    appliedRules: (state) => {
      return filter(state.rules, function (rule) {
        const relevantTriggers = filter(rule.triggers, function (trigger) {
          const relevantQuestionsAnswer = find(state.answers, ['questionId', trigger.questionId]);
          if (relevantQuestionsAnswer) {
            const triggerValues = JSON.parse(trigger.values).map(Number);
            let found = false;
            if (trigger.matchType == 'any') {
              found = find(triggerValues, function (triggerValue) {
                return find(relevantQuestionsAnswer.answers, function (answer) {
                  return answer == triggerValue;
                });
              }) ?? false;
            }
            if (trigger.matchType == 'all') {
              found = !difference(triggerValues, relevantQuestionsAnswer.answers).length;
            }
            return found;
          }
          return false;
        });
        return relevantTriggers.length ?? false;
      });
    },

    appliedRulesTargetingQuestionById: (state, getters) => (questionIdToCheck) => {
      return filter(getters.appliedRules, function (rule) {
        const relevantTargets = filter(rule.targets, ['questionId', questionIdToCheck]);
        return relevantTargets.length ?? false;
      });
    },

    skipAllRules: (state, getters) => {
      return find(getters.appliedRules, ['action', 'skipAll']) ?? false;
    },

    answers: (state) => state.answers,

    answersByQuestionId: (state) => (questionId) => {
      return find(state.answers, ['questionId', questionId]) ?? null;
    },

    answerByQuestionIdAndRefDataId: (state, getters) => (questionId, refDataId) => {
      const questionAnswers = getters.answersByQuestionId(questionId);
      if (questionAnswers) {
        const answer = find(questionAnswers.answers, function (a) {
          return a === refDataId;
        });
        return answer ?? null;
      }
      return null;
    },

    answerModalsByQuestionIds: (state) => (questionIdToGet) => {
      return filter(state.answerModalContents, { assessmentId: state.assessment.assessmentId, questionId: questionIdToGet }) ?? [];
    },

    answerModalContents: (state) => state.answerModalContents,

    answerProsConsLabels: (state) => state.answerProsConsLabels,

    answerProsConsLabelsByQuestionAndRefDataId: (state, getters) => (questionId, refDataId, type) => {
      const questionProsConsLabels = state.answerProsConsLabels[questionId] ?? null;
      if (questionProsConsLabels) {
        const refDataProsConsLabels = questionProsConsLabels[refDataId] ?? null;
        if (refDataProsConsLabels) {
          return refDataProsConsLabels[type] ?? null;
        }
      }
      return null;
    },
  },
};
